import React from 'react'
import './style.css';
import Pageroute from './Route/Route';

const App = () => {
  return (
    <div>
         <Pageroute/>   
    </div>
  )
}

export default App