import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo1 from '../../asset/img/navbar/newLogo.png';
import Logo from '../../asset/img/navbar/pbc_134x42.png';
import palystore from '../../asset/img/navbar/google-play_1.png';
import Triangle from '../../asset/img/navbar/app-store_1.png';
import { Link, useLocation } from 'react-router-dom';
import "./Navbar.css";


function NavScrollExample() {
  const location = useLocation()
  // console.log(location,'locationnnnnn')
  return (

<nav class="container navbar navbar-expand-lg ">
  <div class="container-fluid">
    <a class="navbar-brand" href="/"> <img src={Logo1} alt="Bootstrap"  /></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse " id="navbarSupportedContent">
      {/* <ul class="navbar-nav me-auto mb-2 mb-lg-0"> */}
      <ul style={{margin:'0px 80px',fontSize:'16px'}} class="navbar-nav  mb-2 mb-lg-0 ms-auto newNavbar">
        <li class="nav-item ">
          <a style={{color:location.pathname=='/'?'red':'grey',marginRight:'20px',fontWeight:location.pathname=='/'?'bold':'boldd'}} class="nav-link active" aria-current="page" href="/">Home</a>
        </li>
        {/* <li class="nav-item">
          <a style={{color:location.pathname=='/about'?'red':'grey',marginRight:'20px'}} class="nav-link" href="#about">About Us</a>
        </li> */}
        {/* <li class="nav-item">
          <a style={{color:location.pathname=='/works'?'red':'grey',marginRight:'20px'}} class="nav-link" href="#action2">How it works</a>
        </li> */}
        <li class="nav-item">
          <a style={{color:location.pathname=='/workss'?'red':'grey',marginRight:'20px'}} target='_blank' class="nav-link" href="https://paybycal.com/">Paybycal</a>
        </li>
        <li class="nav-item">
          <a style={{color:location.pathname=='/blog' || location.pathname=='/mainblog'?'red':'grey',marginRight:'20px',fontWeight:location.pathname=='/blog'?'bold':'boldd'}} class="nav-link" href="/blog">Blogs</a>
        </li>
        <li class="nav-item">
          <a style={{color:location.pathname=='/contact'?'red':'grey',marginRight:'20px',fontWeight:location.pathname=='/contact'?'bold':'boldd'}} class="nav-link" href="/contact">Contact</a>
        </li>
        {/* <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            About
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider" /></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li> */}
        
      </ul>
      <form class="d-flex  align-items-center" role="search">
      
          <span style={{color:'red'}}>
          Get the App
          </span>
          <a style={{margin:'0px 10px'}} target='_blank' href="https://play.google.com/store/apps/details?id=com.paybycalexperts "><img src={palystore} alt="" /></a>
         <a target='_blank' href="https://apps.apple.com/in/app/paybycal-experts/id6449674398 "><img src={Triangle} alt="" /></a>
          {/* <img src={palystore} alt="" />
          <img src={Triangle} alt="" /> */}
        
      </form>
    </div>
  </div>
</nav>


  );
}

export default NavScrollExample;