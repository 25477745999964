import React, { useState } from 'react'
import "./contactDetail.css";
import axios from "axios";

export default function ContactDetail() {
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')

  const changeName = (e)=>{
    setName(e.target.value)
  }

  const changeMobile = (e)=>{
    setMobile(e.target.value)
  }

  const validation=()=>{
    if(mobile.length!==10 || name.length ==""){
alert('Please enter details')
    }
    else{
      submitData()
    }
  }

  const submitData = () => {
    
    let url = "https://coach.paybycal.com/api/web_form"
    const body = {
      name: name,
      mobile: mobile
    };
    console.log('bodyyyy', body)
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log('new response', resp.data)
        // console.log(' = = =cartdata = = =', resp.data);
        if (resp.data.status) {
          alert('successfully submited !!')
          setName('')
          setMobile('')
        } else {
          alert('Enter valid data')
        }
      })
      .catch((error) => {
        console.log('errrorrrrrrrrrrrrrr', error)
      });



  }

  return (
    <div className='cDetailmainDiv'>
      <h1>enter your contact details to know more</h1>
      <div className="cDetailBox">
        <input style={{ marginBottom: '10px' }} value={name} onChange={changeName} type="text" placeholder='Enter your name' />
        <input minLength={10} maxLength={10} value={mobile} onChange={changeMobile} type="text" placeholder='Enter your number' />
        <button onClick={validation}>Get Started</button>
        <div className="scrollDiv">
          <p className='scrollP'>Free lifetime registration for first 100 coaches</p>
          <p className='scrollP'>Free lifetime registration for first 100 coaches</p>
          <p className='scrollP'>Free lifetime registration for first 100 coaches</p>
          {/* <p className='scrollP'>Free lifetime registration for first 100 coaches</p> */}
          {/* <p className='scrollP'>Free lifetime registration for first 100 coaches</p>
        <p className='scrollP'>Free lifetime registration for first 100 coaches</p>
        <p className='scrollP'>Free lifetime registration for first 100 coaches</p>
        <p className='scrollP'>Free lifetime registration for first 100 coaches</p> */}
        </div>
      </div>
    </div>
  )
}
