import React from 'react'
import "./card.css";
import img1 from '../../asset/img/whatsay/man.png'
import img2 from '../../asset/img/whatsay/Frame 47.png'
import Carousel from 'react-bootstrap/Carousel';

export default function Cards() {
    return (
        <>
            <div className="cardSuper">
                <div className='cardMainDiv'>
                    {/* <div className="outerDiv"> */}
                    <div className="outerCard">
                        <div className="cardBox">
                            <img src={img1} alt="" />
                        </div>
                        <div className="cardText">
                            <h5>Online/ Offline personalized experts</h5>
                        </div>
                        <div className="cardPara">
                            <p>Experienced gym coach with a passion for helping clients achieve their fitness goals. Skilled in creating personalized workout plans, providing motivation, and ensuring proper form. Dedicated to promoting a healthy lifestyle and guiding individuals towards success.</p>
                        </div>
                    </div>

                    <div className="outerCard">
                        <div className="cardBox2">
                            <img id='cImg2' src={img2} alt="" />
                        </div>
                        <div className="cardText">
                            <h5>Expert Nutritionist</h5>
                        </div>
                        <div className="cardPara">
                            <p>Certified nutritionist specializing in creating tailored dietary plans to optimize health and wellness. Proven track record of guiding clients towards their nutritional goals through evidence-based strategies. Committed to empowering individuals to make sustainable and informed dietary choices.</p>
                        </div>
                    </div>
                    {/* </div> */}
                </div>


                {/*  mobile view  */}
                <Carousel style={{ display: 'none' }} className='cardmobilecontainer '>
                    <Carousel.Item interval={3000}  >
                        <div className="outerCard">
                            <div className="cardBox">
                                <img src={img1} alt="" />
                            </div>
                            <div className="cardText">
                                <h5>Online/ Offline personalized experts</h5>
                            </div>
                            <div className="cardPara">
                                <p>Experienced gym coach with a passion for helping clients achieve their fitness goals. Skilled in creating personalized workout plans, providing motivation, and ensuring proper form. Dedicated to promoting a healthy lifestyle and guiding individuals towards success.</p>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                    <div className="outerCard">
                        <div className="cardBox2">
                            <img src={img2} alt="" />
                        </div>
                        <div className="cardText">
                            <h5>Expert Nutritionist</h5>
                        </div>
                        <div className="cardPara">
                            <p>Certified nutritionist specializing in creating tailored dietary plans to optimize health and wellness. Proven track record of guiding clients towards their nutritional goals through evidence-based strategies. Committed to empowering individuals to make sustainable and informed dietary choices.</p>
                        </div>
                    </div>
                </Carousel.Item>
                </Carousel>
            </div>
        </>
    )
}
