import React from 'react'
import "./trainer.css";
import img1 from '../../asset/img/whatsay/Group 143.png'
import img2 from '../../asset/img/whatsay/Group 144.png'
import img3 from '../../asset/img/whatsay/Group 145.png'
import img4 from '../../asset/img/whatsay/Group 146.png'
import img5 from '../../asset/img/whatsay/newGroup.png'

export default function Trainers() {
  return (
    <div className='trainerMainDiv'> 
      <div className="tMain">
        <div className="tFirst">
            <p>For Trainers</p>
            <h5>Online/Offline Training</h5>
            <h6>Sell more coaching to your clients through both online and offline training programs and boost your profits.</h6>

        </div>
        <div className="tSecond">
            <img src={img5} alt="" />
        </div>
      </div>

      <div className="tMain2">
        <div className="tFirst">
            <p style={{textAlign:'right'}}>for nutritionists</p>
            <h5 style={{textAlign:'right'}}>Personalized diet plans</h5>
            <h6 style={{textAlign:'right'}}>Help your clients reach their ideal journey by giving them personalized diet plans and get your revenues instantly.</h6>

        </div>
        <div style={{marginRight:'40px'}} className="tSecond">
            <img src={img2} alt="" />
        </div>
      </div>

      <div className="tMain">
        <div className="tFirst">
            <p>For Trainers</p>
            <h5>Get top class access to your community</h5>
            <h6>Engage with your clients through the app to keep their motivation at peak and help them achieve their goals.</h6>

        </div>
        <div className="tSecond">
            <img src={img3} alt="" />
        </div>
      </div>

      <div className="tMain2">
        <div className="tFirst">
            <p  style={{textAlign:'right'}}>For Trainers</p>
            <h5  style={{textAlign:'right'}}>Helps to grow your business</h5>
            <h6  style={{textAlign:'right'}}>With integrated payments, appointments, and one-click automation you can interact with your clients easily, by always providing the best experience to your clients.</h6>

        </div>
        <div style={{marginRight:'40px'}} className="tSecond">
            <img src={img4} alt="" />
        </div>
      </div>
    </div>
  )
}
