import React from 'react'
import Navbar from '../Navbar/Navbar'
// import 'bootstrap/dist/css/bootstrap.min.css';
import FooterTwo from '../FooterTwo/FooterTwo'
import Banner from '../Banner/Banner'
import Client from '../client/Client'
import ContactDetail from '../contactDetail/ContactDetail'
import Trainers from '../trainers/Trainers'
import FindOut from '../Find Out/FindOut'
import Cards from '../cards/Cards'


const Home = () => {
  return (
      <>
      <Navbar/>
      <Banner />
      <div style={{background: "linear-gradient(104.31deg, #F95B30 5.98%, #D81C65 93.2%)"}}>
        <Cards />
      <Client />
      <ContactDetail />
      <Trainers />
      <FindOut />
      </div>
      <FooterTwo/>
    </>
  )
}

export default Home