import React from 'react'
import Navbar from '../Navbar/Navbar'
import FooterTwo from '../FooterTwo/FooterTwo'

export default function Privacy() {
  return (
    <>
        <Navbar />
    <h1 style={{fontSize:'30px',margin:'20px', textAlign:'center'}}>Privacy Statement</h1>
    <div className="termDiv" style={{padding:'0px 80px'}}>
  <p>
    HELTHOFIT PVT LTD (Hereinafter “PAYBYCAL EXPERTS” or “we” or “us” or “our”) is committed to protecting privacy of
    its users (“user” or “you”). Data protection of users is high priority for the management of PAYBYCAL EXPERTS. This
    commitment reflects the value we place on earning and keeping the trust of our users, business partners, and others
    who share their personal information with us.
  </p>
  <br />
  <h3 style={{margin:'0% 0%'}}>Scope of The Statement</h3>
  <p>
    This Privacy Statement ("Statement") explains information processing practices of PAYBYCAL EXPERTS. It applies to
    any
    personal information you provide to PAYBYCAL EXPERTS and any personal information we collect from other sources.
    This
    Statement is a statement of our practices and of your rights regarding your personal information. This is not a
    contractual document, and it does not create any rights or obligations on either party beyond those which already
    exist under data protection laws.<br />
    <br />
    This Privacy Statement explains how we collect, use, disclose, and safeguard your information when you visit our
    Website or Mobile Application (the “Application”). Please read this Privacy Statement carefully. IF YOU DO NOT AGREE
    WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE Website/Mobile Application.<br />
    <br />
    The collection and processing of personal data, such as the name, address, e-mail address, or telephone number of a
    data subject shall always be in accordance with the Information Technology Act, 2000, Information Technology Rules,
    2011 adopted by India’s IT Ministry, India’s Ministry of Communications ‘Press Note’ Technology, with clarifications
    and Ministry Of Communications And Information Technology (Department Of Information Technology) Notification Dated
    11th April 2011 and The General Data Protection Regulation (GDPR) as may be applicable to PAYBYCAL EXPERTS. By means
    of these data protection declaration, our enterprise would like to inform the general public of the nature, scope,
    and
    purpose of the personal data we collect, use and process. Furthermore, data subjects are informed, by means of this
    data protection declaration, of the rights to which they are entitled.<br />
    <br />
    PAYBYCAL EXPERTS reserve the right to make changes to this Privacy Statement at any time and for any reason. You are
    encouraged to periodically review this Privacy Statement to stay informed of updates. You will be deemed to have
    been
    made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Statement
    by
    your continued use of the Website/Mobile Application after the date such revised Privacy Statement is posted.
    <br />
    This Statement does not apply to your use of a third-party site linked to on this Website/Mobile Application or any
    third-party website or mobile application which you install or make payments, including any in-app virtual items,
    which may also collect and use data about you. PAYBYCAL EXPERTS is not responsible for any of the data collected by
    any such third party.
    <br />
    The primary point of contact at HELTHOFIT PVT LTD for questions regarding your personal information is,
    support@helthofit.com.
  </p>
<br />
  <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
    What information do we collect?
  </p>

  <p>
    PAYBYCAL EXPERTS collects personal and other information of its users in various ways. The information that may be
    collected via the Website or Mobile Application depends on the content and materials you use, and includes:<br />
    &nbsp;
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Personal Information</u>
  </p>

  <p>
    Demographic and other personally identifiable information (such as your name and email address) that you voluntarily
    give us when you register with the Website/Mobile Application or when choosing to participate in various activities
    related to the Website/Mobile Application, such as your age, gender, address, interests, writing reviews, chat,
    posting messages in comment sections or in our forums, rating gyms, sending feedback, and responding to surveys. If
    you choose to share data about yourself via your profile, online chat, or other interactive areas of the
    Website/Mobile Application, please be advised that all data you disclose in these areas is public and your data will
    be accessible to anyone who accesses the Website/Mobile Application. You are under no obligation to provide us with
    personal information of any kind, however your refusal to do so may prevent you from using certain features of the
    Website/Application.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Derivative Information</u>
  </p>

  <p>
    Information that our servers automatically collect when you access the Website/Mobile Application, such as your
    native actions that are integral to the Website/Mobile Application, including your IP address, your browser type,
    your operating system, your access times, the pages you have viewed directly before and after accessing the
    Website/Mobile Application your liking, rating, re-blogging, or replying to a post, as well as other interactions
    with the Website/Mobile Application and other users via server log files.
  </p>
  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Financial Information</u>
  </p>

  <p>
    Financial information, such as data related to your payment method (e.g., valid credit card/debit card number, card
    brand, expiration date), that we may collect when you purchase, order, exchange, or request information about our
    services from the Website/Mobile Application. We store only very limited, if any, financial information that we
    collect. Otherwise, all financial information is stored by our payment processors and you are encouraged to review
    their privacy policy and contact them directly for responses to your questions. <br />If the data subject resides
    outside India, then the payment has to be made through “Razorpay” or “PayPal” during the ordering process, we
    automatically transmit the data of the data subject to Razorpay or PayPal. By selecting this payment option, the
    data subject agrees to the transfer of personal data required for payment processing. <br />The personal data
    transmitted to Razorpay or PayPal is usually first name, last name, address, email address, IP address, telephone
    number, mobile phone number, or other data necessary for payment processing. The processing of the purchase contract
    also requires such personal data, which are in connection with the respective order. <br />The transmission of the
    data is aimed at payment processing and fraud prevention. The controller will transfer personal data to Razorpay or
    PayPal, in particular, if a legitimate interest in the transmission is given. The personal data exchanged between
    Razorpay or PayPal and the controller for the processing of the data will be transmitted by Razorpay or PayPal to
    economic credit agencies. This transmission is intended for identity and creditworthiness checks. <br />Razorpay
    or PayPal will, if necessary, pass on personal data to affiliates and service providers or subcontractors to the
    extent that this is necessary to fulfill contractual obligations or for data to be processed in the order.
    <br />The data subject has the possibility to revoke consent for the handling of personal data at any time from
    Razorpay or PayPal. A revocation shall not have any effect on personal data, which must be processed, used or
    transmitted in accordance with (contractual) payment processing. The applicable data protection provisions of
    Razorpay or PayPal may be retrieved under the policy provided by Razorpay or PayPal.
  </p>
  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Information we collect over PAYBYCAL EXPERTS Website, Mobile Applications and Social Media</u>
  </p>

  <p>
    For purposes of this Statement, "website" includes our mobile application. <br />We may ask you for some or all of
    the following types of information when you register for activities, training programs, events, request services,
    manage accounts, access various content and features or directly visit our websites. This includes, but is not
    limited to:
  <ul>
    <li>
      Contact information, such as name, e-mail address, postal address, phone number and mobile number;
    </li>
    <li>User name, password, password reminder questions and password answers;</li>
    <li>Communication preferences, such as which newsletters you would like to receive;</li>
    <li>Search queries;</li>
    <li>Contact information about others when you refer a friend to a particular site or service (note: this information
      is used solely to facilitate requested communications); and</li>
    <li>Information posted in community discussions and other interactive online features.</li>
  </ul>
  </p>
  <p>
    In some instances, we automatically collect certain types of information when you visit our websites and
    through e-mails that we may exchange. Automated technologies may include the use of web server logs to collect IP
    addresses, "cookies" and web beacons.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Social Media</u>
  </p>

  <p>
    You can engage with us through social media websites or through features such as plug-ins or applications on
    PAYBYCAL EXPERTS site that integrate with social media sites. You may also choose to link your account with us to
    third party social media sites. When you link your account or engage with us on or through third party social media
    sites, plug-ins, or applications, you may allow us to have ongoing access to certain information from your social
    media account (e.g., name, e-mail address, photo, gender, birthday, the posts or the 'likes' you make). <br />If
    you post information when you interact with our websites through social media sites, plug-ins or other applications,
    depending on your privacy settings, this information may become public on the Internet. You can control what
    information you share through privacy settings available on some social media sites. For more information about how
    you can customize your privacy settings and how third-party social media sites handle your personally identifiable
    information, please refer to their privacy help guides, privacy notices and terms of use.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Mobile Devices</u>
  </p>

  <p>
    If you access our websites on your mobile telephone or mobile device, we may also collect your unique device
    identifier and mobile device IP address, as well as information about your device's operating system, mobile carrier
    and your location information. We may also ask you for consent to provide your mobile phone number (for example, so
    that we can send you push notifications).
  </p>
<br />
  <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
    Use of Personal Information
  </p>

  <p>
    The following is a summary of the purposes for which we use personal information. More information about the
    personal information collected for each of our services, together with the purpose and legal basis for collecting
    the information, will be provided to you in separate privacy notices which are relevant to the services which affect
    you.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Performing services for our clients</u>
  </p>

  <p>
    We process personal information which our clients provide to us in order to perform our professional consultancy and
    training programs based on the plan selected by our client. This may impact you, for example, where you are the
    employee of our client. The precise purposes for which your personal information is processed will be determined by
    the scope and specification of our client engagement, and by applicable laws, regulatory guidance and professional
    standards. It is the obligation of our client to ensure that you understand that your personal information will be
    disclosed to PAYBYCAL EXPERTS.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Administering your engagements</u>
  </p>

  <p>
    We process personal information about you in order to:
  <ul>
    <li>carry out "Know Your Client" checks and screening prior to starting a new activity or training;</li>
    <li>carry out your communication, service, billing and administration;</li>
    <li>deal with your complaints;</li>
    <li>administer sweepstakes, promotions, and contests;</li>
    <li>deliver coupons, newsletters, and promotions, and other information regarding the Website/Mobile Application to
      you;</li>
    <li>Email you regarding your account or payment confirmation;</li>
    <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Website/Mobile
      Application.</li>
  </ul>
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Other uses</u>
  </p>

  <p>
  <ul>
    <li>Assist law enforcement and respond to summons;</li>
    <li>Compile anonymous statistical data and analysis for use internally;</li>
    <li>Create and manage your account;</li>
    <li>Enable user-to-user communications (Only for HELTHOFIT);</li>
    <li>Increase the efficiency and operation of the Website/Mobile Application.</li>
    <li>Monitor and analyze usage and trends to improve your experience with the Website/Mobile Application.</li>
    <li>Notify you of updates to the Website/Mobile Application.</li>
    <li>Offer new products, services, website, mobile applications, and/or recommendations to you.</li>
    <li>Perform other business activities as needed.</li>
    <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
    <li>Provide information to our Representatives and advisors, including attorneys and accountants, to help us comply
      with legal, accounting, or security requirements.</li>
    <li>Prosecute and defend a court, arbitration, or similar legal proceeding.</li>
    <li>Request feedback and contact you about your use of the Website/Mobile Application.</li>
    <li>Resolve disputes and troubleshoot problems.</li>
  </ul>
  </p>

  <p>
    If we wish to use your personal information for a purpose which is not compatible with the purpose for which it was
    collected for, we will request your consent. In all cases, we balance our legal use of your personal information
    with your interests, rights, and freedoms in accordance with applicable laws and regulations to make sure that your
    personal information is not subject to unnecessary risk.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Legal basis</u>
  </p>

  <p>
    All processing or use of your personal information is justified by a "lawful basis" for processing. In limited
    circumstances, we will use your consent as the basis for processing your personal information, for example, where we
    are required to obtain your prior consent in order to send you marketing communications.
  </p>
  <p>Before collecting and/or using any special categories of data, we will establish a lawful exemption which will
    allow us to use that information. This exemption will typically be:
  <ul>
    <li>your explicit consent;</li>
    <li>the establishment, exercise or defense by us or third parties of legal claims.</li>
  </ul>
  </p>

  <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
    Information from Children
  </p>

  <p>
    Our website or mobile application is not directed to children and we do not knowingly collect personal information
    from children on our websites. Children are prohibited from using our website or mobile application.
  </p>
<br />
  <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
    Retention of Your Personal Information
  </p>

  <p>
    How long we retain your personal information depends on the purpose for which it was obtained and its nature. We
    will keep your personal information for the period necessary to fulfill the purposes described in this Statement
    unless a longer retention period is permitted by law and in accordance with the PAYBYCAL EXPERTS Record Retention
    Policy. <br />In specific circumstances we may store your personal information for longer periods of time so that
    we have an accurate record of your dealings with us in the event of any complaints or challenges, or if we
    reasonably believe there is a prospect of litigation relating to your personal information or dealings.
  </p>
<br />
  <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
    Disclosure of Information
  </p>

  <p>
    We do not rent, sell or otherwise disclose personal information about our online visitors with unaffiliated third
    parties for their own marketing use. We do not share your personal information with third parties except in the
    following circumstances discussed below.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Business Partners</u>
  </p>

  <p>
    We disclose personal information to business partners or consultants who provide certain specialized services to us,
    or who co-operate with us on projects. These business partners operate as separate controllers, and are responsible
    for their own compliance with relevant laws. You should refer to their privacy notices for more information about
    their practices.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Authorized Service Providers</u>
  </p>

  <p>
    We may disclose your information to service providers we have retained (as processors) to perform services on our
    behalf (either in relation to services performed for our clients, or information which PAYBYCAL EXPERTS uses for its
    own purposes, such as marketing). These service providers are contractually restricted from using or disclosing the
    information except as necessary to perform services on our behalf or to comply with legal requirements. These
    activities could include any of the processing activities that we carry out as described in the above section, ‘Use
    of Personal Information.’
  </p>

  <p>
    Examples include:
  <ul>
    <li>PAYBYCAL EXPERTS Sales and Support team;</li>
    <li>IT service providers who manage our IT and back-office systems and telecommunications networks;</li>
    <li>marketing automation providers;</li>
    <li>contact center providers.</li>
  </ul>
  </p>

  <p>These third parties appropriately safeguard your data, and their activities are limited to the purposes for which
    your data was provided.</p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Legal Requirements and Business Transfers</u>
  </p>
  <p>We may disclose personal information
    {/* <style>
      ol {
        margin: 0% 10%;
        font-size: 20px;
      }
    </style> */}
  <ol type="i">
    <li>if we are required to do so by law, legal process, statute, rule, regulation, or professional standard, or to
      respond to a subpoena, search warrant, or other legal request. </li>
    <li>in response to law enforcement authority or other government official requests, </li>
    <li>when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss, </li>
    <li>in connection with an investigation of suspected or actual illegal activity or </li>
    <li>in the event that PAYBYCAL EXPERTS is subject to a merger or acquisition to the new owner of the business.</li>
  </ol>
  </p>
  <p>Disclosure may also be required for company audits or to investigate a complaint or security threat.</p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Sale or Bankruptcy</u>
  </p>

  <p>
    If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may
    transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information
    would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that
    the transferee may decline honor commitments we made in this Privacy Statement. We are not responsible for the
    actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or
    control third-party solicitations. If you no longer wish to receive correspondence, emails or other communications
    from third parties, you are responsible for contacting the third party directly.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    Transmission of Information Collected
  </p>

  <p>
    Our organization has its footprint across India and business across the globe and may transfer certain personal
    information across geographical borders to PAYBYCAL EXPERTS entities, authorized service providers, consultants or
    business partners in India and other countries working on our behalf in accordance with applicable laws. Our
    affiliates and third parties may be based locally or they may be overseas. <br />When we do, we use a variety of
    legal mechanisms to help ensure your rights and protections travel with your data:
  <ul>
    <li>we ensure transfers within PAYBYCAL EXPERTS are covered by agreements based on the standard contractual clauses
      as per Indian Laws, which contractually oblige each member to ensure that personal information receives an
      adequate and consistent level of protection wherever it resides within PAYBYCAL EXPERTS;</li>
    <li>where we transfer your personal information outside PAYBYCAL EXPERTS or to third parties who help provide our
      products and services, we obtain contractual commitments from them to protect your personal information; or</li>
    <li>where we receive requests for information from law enforcement or regulators, we carefully validate these
      requests before any personal information are disclosed.</li>
  </ul>
  </p>

  <p>
    If you would like further information about whether your information will be disclosed to overseas recipients,
    please contact us as noted below. You also have a right to contact us for more information about the safeguards we
    have put in place (including a copy of relevant contractual commitments, which may be redacted for reasons of
    commercial confidentiality) to ensure the adequate protection of your personal information when this is transferred
    as mentioned above.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Security for Protection of Information
  </p>

  <p>
    The security of your personal information is important to us and PAYBYCAL EXPERTS has implemented reasonable
    physical, technical and administrative security standards to protect personal information from loss, misuse,
    alteration or destruction. We protect your personal information against unauthorized access, use or disclosure,
    using security technologies and procedures, such as encryption and limited access. Only authorized individuals
    access your personal information, and they receive training about the importance of protecting personal information.
    <br />Our service providers and agents are contractually bound to maintain the confidentiality of personal
    information and may not use the information for any unauthorized purpose.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    What choices do you have about your personal information?
  </p>

  <p>
    We offer certain choices about how we communicate with our clients and what personal information we obtain about
    them and share with others. When you provide us with personal details, if we intend to use those details for
    marketing purposes, we will provide you with the option of whether you wish to receive promotional e-mail, SMS
    messages, telephone calls and postal mail from us. At any time, you may opt out from receiving interest-based
    advertising from us by contacting us.
    <br />You may also choose not to receive marketing communications from us by clicking on the unsubscribe link or
    other
    instructions in our marketing e-mails, visiting the My Account section on our website, or contacting us as noted
    below.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    What choices do you have about your personal information?
  </p>

  <p>
    We offer certain choices about how we communicate with our clients and what personal information we obtain about
    them and share with others. When you provide us with personal details, if we intend to use those details for
    marketing purposes, we will provide you with the option of whether you wish to receive promotional e-mail, SMS
    messages, telephone calls and postal mail from us. At any time, you may opt out from receiving interest-based
    advertising from us by contacting us.
    <br />You may also choose not to receive marketing communications from us by clicking on the unsubscribe link or
    other
    instructions in our marketing e-mails, visiting the My Account section on our website, or contacting us as noted
    below.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    How can you update your communication preferences?
  </p>

  <p>
    We take reasonable steps to provide you with communication about your information. You can update your communication
    preferences in the following ways.
  <ul><br />
    <li><i>Profile</i><br />If you have created a profile or account on one of our Mobile Application, you can update
      your contact information after you log into your account.</li><br />
    <li><i>Newsletters</i><br />If you request electronic communications, such as an e-newsletter, you will be able to
      unsubscribe at any time by following the instructions included in the communication.</li><br />
    <li><i>Mobile Devices</i><br />If you previously chose to receive push notifications on your mobile device from us
      but no longer wish to receive them, you can manage your preferences either through your device or the application
      settings. If you no longer wish to have any information collected by the mobile application, you may uninstall the
      application by using the uninstall process available on your mobile device.</li><br />
    <li><i>E-mail</i><br />Contact us by e-mail or postal address as noted below. Please include your current contact
      information, the information you are interested in accessing and your requested changes.</li>
  </ul>
  </p>

  <p>
    If we do not provide you with access, we will provide you with the reason for refusal and inform you of any
    exceptions relied upon.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Other rights regarding your data
  </p>

  <p>
    Subject to certain exemptions, and in some cases dependent upon the processing activity we are undertaking, you have
    certain rights in relation to your personal information.<br />
    We may ask you for additional information to confirm your identity and for security purposes, before disclosing the
    personal information requested to you. We reserve the right to charge a fee were permitted by law, for instance if
    your request is manifestly unfounded or excessive.<br />
    You can exercise your rights by contacting us. Subject to legal and other permissible considerations, we will make
    every reasonable effort to honor your request promptly or inform you if we require further information in order to
    fulfill your request.<br />
    We may not always be able to fully address your request, for example if it would impact the duty of confidentiality
    we owe to others, or if we are legally entitled to deal with the request in a different way.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Right to Access</u>
  </p>

  <p>
    You have right to access personal information which PAYBYCAL EXPERTS holds about you. If you have created a profile,
    you can access that information by visiting your account.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Right to Rectification</u>
  </p>

  <p>
    You have a right to request us to correct your personal information where it is inaccurate or out of date.
  </p>
  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Right to be Forgotten (Right to Erasure)</u>
  </p>

  <p>
    You have the right under certain circumstances to have your personal information erased. Your information can only
    be erased if your data is no longer necessary for the purpose for which it was collected, and we have no other legal
    ground for processing the data.
  </p>
  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Right to Restrict Processing</u>
  </p>

  <p>
    You have the right to restrict the processing of your personal information, but only where:
  <ul>
    <li>its accuracy is contested, to allow us to verify its accuracy; or</li>
    <li>the processing is unlawful, but you do not want it erased; or</li>
    <li>it is no longer needed for the purposes for which it was collected, but we still need it to establish, exercise
      or defend legal claims; or</li>
    <li>you have exercised the right to object, and verification of overriding grounds is pending.</li>
  </ul>
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Right to Data Portability</u>
  </p>

  <p>
    You have the right to data portability, which requires us to provide personal information to you or another
    controller in a commonly used, machine readable format, but only where the processing of that information is based
    on (i) consent; or (ii) the performance of a contract to which you are a party.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Right to Object to Processing</u>
  </p>

  <p>
    You have the right to object the processing of your personal information at any time, but only where that processing
    has our legitimate interests as its legal basis. If you raise an objection, we have an opportunity to demonstrate
    that we have compelling legitimate interests which override your rights and freedoms.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>International Transfers</u>
  </p>

  <p>
    As noted above, you can ask to obtain a copy of, or reference to, the safeguards under which your personal
    information is transferred outside India.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Contact Us
  </p>

  <p>
    If you have any questions, would like further information about our privacy and information handling practices,
    would like to discuss opt-outs or withdrawing consent, or would like to make a complaint about a breach of the Act
    or this Statement, please contact the Privacy Officer: WILSON GOMES.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Grievance Officer:
  </p>

  <p>
    In accordance with the Information Technology Act, 2000 and the Information Technology (Intermediaries Guidelines)
    Rules, 2011 made thereunder, the name and contact details of the Grievance Officer are provided below: <br />Mr.
    WILSON GOMES <br />www.paybycal.com <br />Address at: - BLD NO 17, Flat No 102, Rose Nagar CHS, Naigaon West,
    Palghar 401207. <br />Contact No.: +91 8108006060 <br />Timing: Monday – Saturday 09:00 – 18:00
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Changes to this Statement
  </p>

  <p>
    We may update this Statement from time to time. When we do, we will post the current version on this site, and we
    will revise the version date located at the bottom of this page. <br />
    We encourage you to periodically review this Statement so that you will be aware of our privacy practices.
    <br />
    This Statement was last updated on February 24, 2023.
  </p>
  </div>
  <FooterTwo />
    </>
  )
}
