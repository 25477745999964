import React from 'react'
import Navbar from '../Navbar/Navbar'
import FooterTwo from '../FooterTwo/FooterTwo'
import "./terms.css";


export default function Terms() {
  <style>
    
  </style>
  return (
    <>
    <Navbar />
    

<h1 style={{textAlign:'center', fontSize:'40px',margin:'20px'}}>Terms & Conditions</h1>
<div className="termDiv" style={{padding:'0px 80px'}}>
  <p style={{fontWeight:'bold'}}>
    This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as
    applicable and the amended provisions pertaining to electronic records in various statutes as amended by the
    Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any
    physical or digital signatures.
    This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology
    (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms
    of Use for access or usage of a computer resource.
  </p>
  <p>
    THESE TERMS OF USE (THE "TERMS OF USE") ARE ISSUED BY HELTHOFIT PVT LTD AND ITS DIRECTLY AND INDIRECTLY HELD
    COMPANIES (HEREINAFTER, “PAYBYCAL EXPERTS”). THEY APPLY TO ALL WEBSITES, BLOGGING SITES, MOBILE APPLICATIONS AND
    OTHER ONLINE DIALOGUE PLATFORMS OWNED OR MANAGED BY OR ON BEHALF OF PAYBYCAL EXPERTS UNDER THE URL WWW.PAYBYCAL.COM
    By accessing the PAYBYCAL EXPERTS Website/Mobile Application and any page thereof, you agree to be bound by these
    terms of use and to comply with any applicable laws and regulations. If you do not agree to these Terms of Use, do
    not proceed to further pages of the PAYBYCAL EXPERTS. <br />
    PAYBYCAL EXPERTS reserves the right to vary or amend these Terms of Use at any time and PAYBYCAL EXPERTS is under no
    obligation to inform you about such variations or amendments. The most recent version of the Terms of Use will
    govern the services on PAYBYCAL EXPERTS Website/Mobile Application and will always be available on the PAYBYCAL
    EXPERTS Website/Mobile Application. By continuing to access or use this PAYBYCAL EXPERTS Website/Mobile Application
    after any changes become effective, you agree to be bound by the revised Terms of Use. Please visit these Terms of
    Use on a regular basis to ensure that you have read the latest version. These Terms of Use were updated the last
    time on the date indicated at the end of the Terms of Use. <br />
    The headings used for each of the paragraphs of these Terms of Use do not affect its interpretation. For the purpose
    of these Terms of Use, “Content” means any text, written work, software, database, format, graphic, image, photo,
    video clip, podcast, listing or any other information or material which appears on or forms part of a PAYBYCAL
    EXPERTS Website/Mobile Application.
  </p>
  <br />
  <h3 style={{margin:'0% 0%'}} >Company Information and Mobile application Operation</h3>
  <p>
    PAYBYCAL EXPERTS a part of PAYBYCAL is established with the intent of providing comprehensive software to Trainers,
    Coaches, Fitness Experts, Nutritionists, Dietician to make their clients Activity plan and Meal plan. Post
    Registration the user can create his profile by adding his details and start making his/her client’s plan. The User
    can charge his fees according to his level and experience and PAYBYCAL EXPERTS will charge a commission on each
    transaction between User and their Client. Once payment done by users’ client, the user has to consult and provide
    the plan according to their client’s goal within 48hrs. If not provided within given time, then the client can
    demand for refund. Also, if the client is unhappy with the given plan, he can demand for refund within 5 days and
    the user will not be eligible for that particular payment made by the client. PAYBYCAL EXPERTS has the complete
    authority to terminate the Users account at any given point of time without prior notice.
    <br />
    PAYBYCAL EXPERTS Website/Mobile Application includes content relating to HELTHOFIT PVT LTD and its businesses.
    www.paybycal.com is provided by HELTHOFIT PVT LTD having registered office at BLD NO 17, Flat No 102, Rose Nagar
    CHS, Naigaon West, Palghar 401207.
  </p>
<br />
  <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
    User Registration
  </p>
  <p>
    User may be required to register with the Website/Mobile Application. User agrees to keep his password confidential
    and will be responsible for all use of his account and password. PAYBYCAL EXPERTS reserve the right to remove,
    reclaim, or change a username that would be selected by user if PAYBYCAL EXPERTS determine, in its sole discretion,
    that such username is inappropriate, obscene, or otherwise objectionable.
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    Conduct Of User
  </p>

  <p>
  <ol type="1">
    <li>User may post their reviews, ratings or comments and submit suggestions, ideas, questions, or other information,
      so long as the Contents are not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of
      intellectual property rights, or otherwise injurious to third parties or objectionable and do not consist of or
      contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any
      form of "spam". User shall not use a false e-mail address, impersonate any person or entity, or otherwise mislead
      as to the origin of the Contents. However, user acknowledge that PAYBYCAL EXPERTS does not pre-screen the Content,
      but that PAYBYCAL EXPERTS and its designees shall have the right (but not the obligation) in their sole discretion
      to accept, refuse, move, or remove any Contents that are available via the website or social media platform.</li>
    <li>User understands that all Contents, whether publicly posted or privately transmitted, are the sole
      responsibility of the person from which such Content originated. This means that the user, and not PAYBYCAL
      EXPERTS, is entirely responsible for all Content that the user has provided PAYBYCAL EXPERTS with in order to
      upload, post, transmit or otherwise make available via the website or social media platform.</li>
    <li>User expressly agree not to use the Website/Mobile Application to: (i) harm any user in any manner either
      directly or indirectly; (ii) forge headers or otherwise manipulate identifiers in order to disguise the origin of
      any Contents transmitted through the website; (iii) act in a manner that negatively affects other user's ability
      to engage in real time exchanges or "stalk" or in any other manner harass another user; (iv) interfere with or
      disrupt the website or servers or networks connected to the website, or disobey any requirements, procedures,
      policies or regulations of networks connected to the website; (v) upload or transmit any material that is deemed
      derogatory with respect to the name, services or Contents relating to or provided by PAYBYCAL EXPERTS; (vi)
      intentionally or unintentionally violate any applicable local, state, national or international laws;</li>
    <li>By using the Website/Mobile Application, user represent and warrant that: (i) All registration information that
      user submit will be true, accurate, current, and complete; (ii) User will maintain the accuracy of such
      information and promptly update such registration information as necessary; (iii) User have the legal capacity and
      user agree to comply with these Terms and Conditions; (iv) User is not a minor in the jurisdiction in which he
      resides; (v) User will not access the Website/Mobile Application through automated or non-human means, whether
      through a bot, script or otherwise; </li>
    <li>If user provide any information that is untrue, inaccurate, not current, or incomplete, PAYBYCAL EXPERTS has the
      right to suspend or terminate user account and refuse any and all current or future use of the Website/Mobile
      Application (or any portion thereof).</li>
  </ol>
  </p>

  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Fees and Payment
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    PAYBYCAL EXPERTS accept the following forms of payment:
  <ul  style={{listStyleType:'- '}}>
  {/* <ul style="list-style-type: '- ';"> */}
    <li>Debit Card</li>
    <li>Credit Card</li>
    <li>Razorpay</li>
    <li>PayPal</li>
    <li>Mobile Wallet</li>
  </ul>
  </p>

  <p>
    User may be required to purchase or pay a fee to access our additional services. User agrees to provide current,
    complete, and accurate purchase and account information for all purchases made via the Website/Mobile Application.
    User further agrees to promptly update account and payment information, including email address, payment method, and
    payment card expiration date, so that PAYBYCAL EXPERTS can complete user’s transactions and contact them as needed.
    Users will get in app wallet where they can see their gold calcoins & silver calcoins. Once payment done by users’
    client on app it will immediately reflect on user’s wallet and user can raise ticket for bank account transfer after
    7
    days. User need to provide his GST number if his annual individual turnover is above 20lacs. Gold calcoins can be
    transferred to user’s bank account OR can be used for purchasing products from paybycal app store and Silver
    calcoins
    can only be used for purchasing products from paybycal app store. PAYBYCAL EXPERTS reserves the right to change
    prices
    at any time. All payments shall be in Indian National Rupees and US Dollars for Users from other Countries. <br />
    User agrees to pay all charges or fees at the prices then in effect for purchases, and user authorize PAYBYCAL
    EXPERTS
    to charge their chosen payment provider for any such amounts upon making purchase.<br />
    PAYBYCAL EXPERTS reserves the right to correct any errors or mistakes in pricing, even if it has already requested
    or
    received payment. PAYBYCAL EXPERTS also reserve the right to refuse any order placed through the Website/Mobile
    Application.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Third Party Statements and Quotes
  </p>

  <p>
    The Website/Mobile Application may include statements and quotes of third parties, including service providers,
    relating to PAYBYCAL EXPERTS and its business operations. Such statements and quotes are generally labeled as
    information provided by third parties. PAYBYCAL EXPERTS does not and is under no obligation to verify the content of
    third-party statements or quotes on the website/mobile application. The liability of PAYBYCAL EXPERTS for the
    accuracy or completeness of such third-party statements and quotes shall be excluded to the extent permitted by
    applicable law, rules and regulations.
  </p>
  <br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Prohibited Activities
  </p>

  <p>
    User should not access or use the Website/Mobile Application for any purpose other than that for which PAYBYCAL
    EXPERTS make the Website/Mobile Application available. The Website/Mobile Application may not be used in connection
    with any commercial endeavors except those that are specifically endorsed or approved by PAYBYCAL EXPERTS. <br />
    As a user of the Website/Mobile Application, you agree not to:
  <ol type="1">
    <li>Retrieve data or other content from the Website/Mobile Application to create or compile, directly or indirectly,
      a collection, compilation, database, or directory without written permission from PAYBYCAL EXPERTS.</li>
    <li>Make any unauthorized use of the Website/Mobile Application, including collecting usernames and/or email
      addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user
      accounts by automated means or under false pretenses.</li>
    <li>Use a buying agent or purchasing agent to make purchases on the Website/Mobile Application.</li>
    <li>Use the Website/Mobile Application to advertise or offer to sell goods and services. </li>
    <li>Circumvent, disable, or otherwise interfere with security-related features of the Website/Mobile Application,
      including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of
      the Website/Mobile Application and/or the Content contained therein.</li>
    <li>Engage in unauthorized framing of or linking to the Website/Mobile Application.</li>
    <li>Trick, defraud, or mislead PAYBYCAL EXPERTS and other users, especially in any attempt to learn sensitive
      account information such as user passwords.</li>
    <li>Make improper use of PAYBYCAL EXPERTS support services or submit false reports of abuse or misconduct.</li>
    <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data
      mining, robots, or similar data gathering and extraction tools.</li>
    <li>Interfere with, disrupt, or create an undue burden on the Website/Mobile Application or the networks or services
      connected to the Website/Mobile Application.
    </li>
    <li>Sell or otherwise transfer your profile.</li>
    <li>Use any information obtained from the Website/Mobile Application in order to harass, abuse, or harm another
      person.</li>
    <li>Use the Website/Mobile Application as part of any effort to compete with PAYBYCAL EXPERTS or otherwise use the
      Website/Mobile Application and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
    <li>Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a
      part of the Website/Mobile Application.</li>
    <li>Attempt to bypass any measures of the Website/Mobile Application designed to prevent or restrict access to the
      Website/Mobile Application, or any portion of the Website/Mobile Application.
    </li>
    <li>Harass, annoy, intimidate, or threaten any of PAYBYCAL EXPERTS employees or agents engaged in providing any
      portion of the Website/Mobile Application to you.</li>
    <li>Delete the copyright or other proprietary rights notice from any Content.</li>
    <li>Copy or adapt the Website/Mobile Application’s software, including but not limited to Flash, PHP, HTML,
      JavaScript, Angular or any other code.</li>
    <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including
      excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any
      party’s uninterrupted use and enjoyment of the Website/Mobile Application or modifies, impairs, disrupts, alters,
      or interferes with the use, features, functions, operation, or maintenance of the Website/Mobile Application.</li>
    <li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
      information collection or transmission mechanism, including without limitation, clear graphics interchange formats
      (“gifs”), web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection
      mechanisms” or “pcms”).</li>
    <li>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or
      distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or
      offline reader that accesses the Website/Mobile Application, or using or launching any unauthorized script or
      other software.</li>
    <li>Disparage, tarnish, or otherwise harm, in the opinion of the Website/Mobile Application.</li>
    <li>Use the Website/Mobile Application in a manner inconsistent with any applicable laws or regulations.</li>
  </ol>
  </p>

  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Limitation of Liability
  </p>

  <p>
    In consideration of being allowed to create plan of activities and structured diet programs offered on PAYBYCAL
    EXPERTS for Users Clients, and to use its structured diet programs and training schedule, you or any third party do
    hereby waive, release and forever discharge and hold harmless PAYBYCAL EXPERTS. and its directors, consultants,
    officers, agents, and employees from any and all responsibility, liability, cost and expenses, including injuries or
    any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit,
    lost revenue, loss of data, or other damages, resulting from your participation in any activities, or of any
    structured diet programs offered on PAYBYCAL EXPERTS provided by you. You do also hereby release PAYBYCAL EXPERTS
    its directors, consultants, officers, agents and employees from any responsibility or liability for any injury,
    damage or disorder (physical, metabolic, or otherwise) to your clients, or in any way arising out of or connected
    with your participation in any activities on PAYBYCAL EXPERTS Website/Mobile Application.
  </p>

  <p>You understand and you are aware that strength, flexibility, and aerobic exercise, including the use of equipment
    are a potentially hazardous activity. You also understand that fitness activities involve a risk of injury and even
    death, and that you are voluntarily participating in making your clients plans as you’re a certified fitness
    professional. You hereby agree to expressly assume and accept any and all risks of injury or death related to said
    fitness activities. In addition, you certify that you are not minor, that is to say, you are 18 years of age or
    older. You do hereby further declare yourself to be physically and mentally sound and suffering from no condition,
    impairment, disease, infirmity or other illness that would affect in making inappropriate fitness plan.</p>

  <p><br />IN NO EVENT WILL HELTHOFIT PVT LTD OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD
    PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
    PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE WEBSITE/MOBILE APPLICATION, EVEN
    IF PAYBYCAL EXPERTS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Limitation of Warranties
  </p>

  <p>
    Content contained on the Website/Mobile Application is provided “as is” and “as available”. PAYBYCAL EXPERTS and
    third-party Content providers give no guarantee, representation, warranty or condition, express or implied,
    statutory or otherwise, as to condition, satisfactory quality, performance, fitness for purpose, non-infringement,
    merchantability or otherwise in respect of PAYBYCAL EXPERTS Website/Mobile Application or their Content. Without
    limiting the foregoing, PAYBYCAL EXPERTS and third-party Content providers do not guarantee, represent or warrant
    the accuracy, timeliness, completeness, reliability or availability of the Website/Mobile Application or the
    information or results obtained from them (whether based on manual access, subscriptions, or otherwise), or that the
    Website/Mobile Application or downloads (e.g., PDF, pictures, podcasts, videos) from the Website/Mobile Application
    are virus-free or error-free. All such guarantees, representations, warranties and conditions are excluded, except
    to the extent that their exclusion is not permitted by applicable laws, rules and regulations. <br />
    PAYBYCAL EXPERTS and other Service Providers (without limiting the foregoing) disclaim all guarantees,
    representations, warranties and conditions with respect to the operation of the Platform and related App for
    PAYBYCAL EXPERTS, express or implied, including, but not limited to, the implied guarantees, representations,
    warranties or conditions of merchantability, fitness for particular purpose and the non-infringement. PAYBYCAL
    EXPERTS and the Service Provider disclaim all guarantees, representations, warranties or conditions that the
    Platform and related App are not interrupted or error free.
  </p>
<br />
  <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
    Contributions to PAYBYCAL EXPERTS
  </p>

  <p>
    You may provide Content to the Website/Mobile Application by using relevant functionalities on PAYBYCAL EXPERTS, by
    logging-in, blogs, message boards, online forums, by e-mail or otherwise ("Contributions"). Furthermore, PAYBYCAL
    EXPERTS may invite users to provide Contributions in own- or third-party surveys or polls and on a particular issue
    raised. If you provide Contributions to PAYBYCAL EXPERTS, PAYBYCAL EXPERTS is entitled, but not in any way obliged,
    to use and make available all or parts of your unmodified or modified Contributions on the Website/Mobile
    Application. PAYBYCAL EXPERTS will not mention your name or contact details, unless you have agreed and you have
    logged in with an identified account. If you provide Contributions to PAYBYCAL EXPERTS, you grant PAYBYCAL EXPERTS
    all necessary rights, if any, or license to use, reproduce, distribute, modify, and publicly display your
    Contributions on the Website/Mobile Application for an unlimited period of time.
  </p>

  <p>By submitting a Contribution, you guarantee, represent and warrant that the Contribution complies with applicable
    laws, rules and regulations and in particular:
  <ul>
    <li>Is legal, decent and truthful and not defamatory, unreliable, misleading or otherwise objectionable;</li>
    <li>That you own or have the necessary rights, licenses, consents and permissions, without the need for any
      permission from or payment to any other person or entity, to exploit, and to authorize PAYBYCAL EXPERTS to
      exploit, such Contribution in all manners contemplated by these Terms of Use; </li>
    <li>Does not harm others, in particular minors, or infringe their personal rights.</li>
    <li>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or
      otherwise objectionable (as determined by us).</li>
    <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
    <li>Your Contributions do not include any offensive comments that are connected to race, national origin, gender,
      sexual preference, or physical handicap.</li>
    <li>Your Contributions do not contain any material that solicits personal information from anyone under the age of
      18 or exploits people under the age of 18 in a sexual or violent manner.</li>
    <li>Your Contributions do not violate any Central or State law concerning child pornography, or otherwise intended
      to protect the health or well-being of minors.</li>
  </ul>
  </p>

  <p>
    Users must not provide Contributions which:
  <ul>
    <li>Contain a virus, so-called Trojan Horse, or any other program that could damage data;</li>
    <li>Amount to advertising material or unsolicited (so-called "spam") material;</li>
    <li>Contain inaccurate warnings of viruses, defects or similar material;</li>
    <li>Solicit or requests participation in any lottery, snowball system, chain letter, pyramid game or similar
      activity.</li>
  </ul>
  </p>

  <p>
    You hereby agree to indemnify, defend, and hold PAYBYCAL EXPERTS harmless from and against any and all losses,
    damages, liabilities and costs (including settlement costs and any legal or other fees and expenses for
    investigating or defending any actions or threatened actions) incurred by PAYBYCAL EXPERTS in connection with any
    claim arising out of any breach by you of these Terms of Use or claims arising from your use of the Website/Mobile
    Application. You shall use your best efforts to cooperate with PAYBYCAL EXPERTS in the defense of any claim. We
    reserve the right, at our own expense, to employ separate counsel and assume the exclusive defense and control of
    any matter otherwise subject to indemnification by you. <br />
    PAYBYCAL EXPERTS may deny you access to the Website/Mobile Application at any time, in particular if you breach any
    of the above provisions.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Intellectual Property Rights
  </p>

  <p>
    You may not allocate, distribute, or reproduce in any way any copyrighted material, Designs, trademarks or other
    Intellectual proprietary Information belonging to others without obtaining the prior written consent of the owner of
    such proprietary rights. It is the policy of PAYBYCAL EXPERTS Website/Mobile Application to terminate user
    privileges who once infringes the copyright rights of others upon receipt of prompt notification to the
    Website/Mobile Application by the copyright owner or the copyright owner's legal agent/Advisor. Without limiting the
    foregoing, if you believe that your work has been copied and posted on the PAYBYCAL EXPERTS services in a way that
    constitutes copyright infringement, please provide us with the following information:
  <ol type="1">
    <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright
      interest;</li>
    <li>a description of the copyrighted work that you claim has been infringed;</li>
    <li>a description of where the material that you claim is infringing is located on the website;</li>
    <li>your address, telephone number, and email address;</li>
    <li>a written statement by you that you have a good faith belief that the disputed use is not authorized by the
      copyright owner, its agent, or the law;</li>
    <li>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and
      that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
  </ol>
  </p>

  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Association / Links to Third Party
  </p>

  <p>
    PAYBYCAL EXPERTS Website/Mobile Application may contain links to third party websites "Linked Sites" ("Affiliates" /
    "Third Party Websites"). These Linked Sites are not under the control of www.helthofit.com and the Website/Mobile
    Application is not responsible for the products of any Linked Site, including without limitation any link contained
    in a Linked Site, or any changes or updates to a Linked website. Such Third-Party Websites and Third-Party Content
    are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not
    responsible for any Third-Party Websites accessed through the Website/Mobile Application or any Third-Party Content
    posted on, available through, or installed from the Website/Mobile Application, including the content, accuracy,
    offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party
    Websites or the Third-Party Content. PAYBYCAL EXPERTS Website/Mobile Application is not responsible for web casting
    or any other form of transmission received from any Linked website nor is responsible if the Linked Site is not
    working appropriately. Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites
    or any Third-Party Content does not imply approval or endorsement thereof by PAYBYCAL EXPERTS. If you decide to
    leave the Website/Mobile Application and access the Third-Party Websites or to use or install any Third-Party
    Content, you do so at your own risk, and you should be aware these Terms and Conditions no longer govern. You are
    responsible for viewing and abiding by the privacy statements and terms of use posted at the Linked Sites. Any
    purchases you make through Third-Party Websites will be through other websites and from other companies, and we take
    no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable
    third party. You agree and acknowledge that we do not endorse the products or services offered on Third-Party
    Websites and you shall hold us harmless from any harm caused by your purchase of such products or services.
    Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to or
    resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Mobile Application License
  </p>

  <p style={{fontSize:'20px', fontWeight:'300'}}>
    <u>Use License</u><br />
    If you access PAYBYCAL EXPERTS via a mobile application, then we grant you a revocable, non-exclusive,
    non-transferable, limited right to install and use the mobile application on wireless electronic devices owned or
    controlled by you, and to access and use the mobile application on such devices strictly in accordance with the
    terms and conditions of this mobile application license contained in these Terms and Conditions.
  </p>

  <p>
    You shall not:
  <ol type="1">
    <li>decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the application;</li>
    <li>make any modification, adaptation, improvement, enhancement, translation, or derivative work from the
      application;</li>
    <li>violate any applicable laws, rules, or regulations in connection with your access or use of the application;
    </li>
    <li>remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us
      or the licensors of the application;</li>
    <li>use the application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is
      not designed or intended;</li>
    <li>make the application available over a network or other environmental permitting access or use by multiple
      devices or users at the same time;</li>
    <li>use the application for creating a product, service, or software that is, directly or indirectly, competitive
      with or in any way a substitute for the application;</li>
    <li>use the application to send automated queries to any website or to send any unsolicited commercial e-mail; or
    </li>
    <li>use any proprietary information or any of PAYBYCAL EXPERTS interfaces or other intellectual property of PAYBYCAL
      EXPERTS in the design, development, manufacture, licensing, or distribution of any applications, accessories, or
      devices for use with the application.</li>
  </ol>
  </p>

  <p>
    The following terms apply when you use a mobile application obtained from either the Apple Store or Google Play
    (“App Distributor”) to access the Website/Mobile Application:
  <ol type="1" style={{paddingLeft:'2%'}}>
    <li>the license granted to you for PAYBYCAL EXPERTS mobile application is limited to a non-transferable license to
      use the application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and in
      accordance with the usage rules set forth in the applicable App Distributor’s terms of service;
    </li>
    <li>PAYBYCAL EXPERTS is responsible for providing any maintenance and support services with respect to the mobile
      application as specified in the terms and conditions of this agreement. </li>
    <li>Application license contained in these Terms and Conditions or as otherwise required under applicable law, and
      you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support
      services with respect to the mobile application;</li>
    <li>In the event of any failure of the mobile application to conform to any applicable warranty, you may notify the
      applicable App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the
      purchase price, if any, paid for the mobile application, and to the maximum extent permitted by applicable law,
      the App Distributor will have no other warranty obligation whatsoever with respect to the mobile application;
    </li>
    <li>You represent and warrant that: (a) you are not located in a country that is subject to a Indian government
      embargo, or that has been designated by the Indian government as a “terrorist supporting” country and (b) you are
      not listed on any Indian government list of prohibited or restricted parties;</li>
    <li>You must comply with applicable third-party terms of agreement when using the mobile application, e.g., if you
      have a VoIP application, then you must not be in violation of their wireless data service agreement when using the
      mobile application; and</li>
    <li>You acknowledge and agree that the App Distributors are third-party beneficiaries of the terms and conditions in
      this mobile application license contained in these Terms and Conditions, and that each App Distributor will have
      the right (and will be deemed to have accepted the right) to enforce the terms and conditions in this mobile
      application license contained in these Terms and Conditions against you as a third-party beneficiary thereof.</li>
  </ol>
  </p>

  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Website / Mobile Application Management
  </p>

  <p>
    PAYBYCAL EXPERTS reserves the right, but not the obligation, to:
  <ol type="1">
    <li>monitor the Website/Mobile Application for violations of these Terms and Conditions;</li>
    <li>take appropriate legal action against anyone who, in the sole discretion of PAYBYCAL EXPERTS, violates the law
      or these Terms and Conditions, including without limitation, reporting such user to law enforcement authorities;
    </li>
    <li>PAYBYCAL EXPERTS, in its sole discretion and without limitation, refuse, restrict access to, limit the
      availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion
      thereof;</li>
    <li>PAYBYCAL EXPERTS, in its sole discretion and without limitation, notice, or liability, to remove from the
      Website/Mobile Application or otherwise disable all files and content that are excessive in size or are in any way
      burdensome to systems of PAYBYCAL EXPERTS; and</li>
    <li>otherwise manage the Website/Mobile Application in a manner designed to protect rights and property of PAYBYCAL
      EXPERTS and to facilitate the proper functioning of the Website/Mobile Application.</li>
  </ol>
  </p>

  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Guidelines for Reviews
  </p>

  <p>
    PAYBYCAL EXPERTS may provide you areas on the Website/Mobile Application to leave reviews or ratings. When posting a
    review, you must comply with the following criteria:
  <ol type="1">
    <li>you should have firsthand experience with the person/entity being reviewed;</li>
    <li>your reviews should not contain offensive profanity, or abusive, racist, offensive, or hate language;
    </li>
    <li>your reviews should not contain discriminatory references based on religion, race, gender, national origin, age,
      marital status, sexual orientation, or disability;</li>
    <li>your reviews should not contain references to illegal activity;</li>
    <li>you should not be affiliated with competitors if posting negative reviews;</li>
    <li>you should not make any conclusions as to the legality of conduct;</li>
    <li>you may not post any false or misleading statements; and</li>
    <li>you may not organize a campaign encouraging others to post reviews, whether positive or negative.</li>
  </ol>
  </p>


  <p>PAYBYCAL EXPERTS may accept, reject, or remove reviews in its sole discretion. PAYBYCAL EXPERTS have absolutely no
    obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate.
    Reviews are not endorsed by PAYBYCAL EXPERTS, and do not necessarily represent its opinions or the views of any of
    our affiliates or partners. We do not assume liability for any review or for any claims, liabilities, or losses
    resulting from any review. By posting a review, you hereby grant to PAYBYCAL EXPERTS a perpetual, non-exclusive,
    worldwide, royalty-free, fully-paid, assignable, and sub licensable right and license to reproduce, modify,
    translate, transmit by any means, display, perform, and/or distribute all content relating to reviews.
  </p>
<br />
  <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
    Privacy
  </p>

  <p>
    You should carefully read our full Privacy Policy before deciding to become a User as it is hereby incorporated into
    this Agreement by reference and governs our treatment of any information, including personally identifiable
    information you submit to PAYBYCAL EXPERTS. Please note that certain information, statements, data, and content
    (such as photographs) which you may submit to the Website/Mobile Application, or groups, or are likely to, reveal
    your gender, ethnic origin, nationality, age, and/or other personal information about you. You acknowledge that your
    submission of any information, statements, data, and content to us is voluntary on your part.
  </p>
<br />
  <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
    Disclaimer
  </p>

  <p>
    DO NOT RELY ON PAYBYCAL EXPERTS WEBSITE/MOBILE APPLICATION, ANY INFORMATION THEREIN, OR ITS CONTINUATION. PAYBYCAL
    PROVIDE THE PLATFORM AND INFORMATION ABOUT ITS SERVICES WHICH ARE ON “AS IS” AND “AS AVAILABLE” BASIS.
    WEBSITE/MOBILE APPLICATION DOES NOT CONTROL OR VET USER GENERATED CONTENT FOR ACCURACY. PAYBYCAL EXPERTS DO NOT
    PROVIDE ANY EXPRESS WARRANTIES OR REPRESENTATIONS.
    <br />
    TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, PAYBYCAL EXPERTS DISCLAIMS ANY AND ALL IMPLIED WARRANTIES
    AND REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
    PURPOSE, TITLE, ACCURACY OF DATA, AND NON-INFRINGEMENT. IF YOU ARE DISSATISFIED OR HARMED BY THE WEBSITE OR ANYTHING
    RELATED TO THE WEBSITE, YOU MAY CLOSE YOUR ACCOUNT AND TERMINATE THIS AGREEMENT IN ACCORDANCE WITH “TERMINATION”
    CLAUSE OF THIS AGREEMENT AND SUCH TERMINATION SHALL BE YOUR SOLE AND EXCLUSIVE REMEDY.
    <br />
    PAYBYCAL EXPERTS IS NOT RESPONSIBLE, AND MAKES NO REPRESENTATIONS OR WARRANTIES FOR THE DELIVERY OF ANY MESSAGE
    (SUCH AS INMAILS, POSTING OF ANSWERS OR TRANSMISSION OF ANY OTHER USER GENERATED CONTENT) SENT THROUGH THE WEBSITE
    TO ANYONE. IN ADDITION, PAYBYCAL EXPERTS NEITHER WARRANT NOR REPRESENT USE OF USER FOR THE SERVICES AND WILL NOT
    INFRINGE THE RIGHTS OF THIRD PARTIES. ANY MATERIAL, SERVICE, OR TECHNOLOGY DESCRIBED OR USED ON THE WEBSITE MAY BE
    SUBJECT TO INTELLECTUAL PROPERTY RIGHTS OWNED BY THIRD PARTIES WHO HAVE LICENSED SUCH MATERIAL, SERVICE, OR
    TECHNOLOGY TO HELTHOFIT PVT LTD.
    <br />
    THE WEBSITE DOES NOT HAVE ANY OBLIGATION TO VERIFY THE IDENTITY OF THE PERSONS SUBSCRIBING TO ITS SERVICES, NOR DOES
    IT HAVE ANY OBLIGATION TO MONITOR THE USE OF ITS SERVICES BY OTHER USERS OF THE COMMUNITY; THEREFORE, PAYBYCAL
    EXPERTS DISCLAIMS ALL LIABILITY FOR IDENTITY THEFT OR ANY OTHER MISUSE OF YOUR IDENTITY OR INFORMATION.
    <br />
    THE WEBSITE DOES NOT GUARANTEE THAT THE SERVICES IT PROVIDES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS IN
    FUNCTIONING. IN PARTICULAR, THE OPERATION OF THE SERVICES MAY BE INTERRUPTED DUE TO MAINTENANCE, UPDATES, OR SYSTEM
    OR NETWORK FAILURES. PAYBYCAL EXPERTS DISCLAIMS ALL LIABILITY FOR DAMAGES CAUSED BY ANY SUCH INTERRUPTION OR ERRORS
    IN FUNCTIONING. FURTHERMORE, IT DISCLAIMS ALL LIABILITY FOR ANY MALFUNCTIONING, IMPOSSIBILITY OF ACCESS, OR POOR USE
    CONDITIONS OF THE WEBSITE DUE TO INAPPROPRIATE EQUIPMENT, DISTURBANCES RELATED TO INTERNET SERVICE PROVIDERS, TO THE
    SATURATION OF THE INTERNET NETWORK, AND FOR ANY OTHER REASON.
  </p>
<br />
  <p class="h3" style={{fontSize:'20px', fontWeight:'600'}}>
    Termination
  </p>

  <p>
    This Agreement shall remain in full force and effect while you use the Website/Mobile Application. However, the
    Website/Mobile Application reserves the right to terminate access to certain areas or features of the Website/Mobile
    Application at any time for any reason, with or without notice.
    <br />
    PAYBYCAL EXPERTS also reserves the universal right to deny access to particular users to any/all of its
    services/activities/training program/content without any prior notice/explanation in order to protect the interests
    of the website and/or other visitors to the Website/Mobile Application. PAYBYCAL EXPERTS reserves the right to
    limit, deny or create different access to the Website/Mobile Application and its features with respect to different
    user(s), or to change any of the features or introduce new features without prior notice. The website withholds the
    right to temporary or permanent termination of any user for any of the following reasons:
  <ul style={{listStyleType:'none'}}>
    <li>(a) If it concludes that the user(s) have provided any false information in connection with the member account
      to the website, or are engaged in fraudulent or illegal activities.
    </li>
    <li>(b) The user(s) breach any provisions of the terms and conditions of use agreement and/or Agreement of website
    </li>
    <li>(c) Utilize the Website/Mobile Application to send spam messages or repeatedly publish the same product or
      service information.</li>
    <li>(d) Post any material to members that are not related to international trade or business cooperation.</li>
    <li>(e) Impersonate or unlawfully use other companies name to post information or conduct business of any form.</li>
    <li>(f) Any unauthorized access, use, modification, or control of the website data base, network or related
      services.</li>
  </ul>
  </p>

  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Indemnification
  </p>

  <p>
    You agree to defend, indemnify, and hold PAYBYCAL EXPERTS harmless, including its subsidiaries, affiliates, and all
    of PAYBYCAL EXPERTS respective officers, agents, partners, and employees, from and against any loss, damage,
    liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or
    arising out of:
  <ol type="1">
    <li>your Contributions;</li>
    <li>use of the Website/Mobile Application;</li>
    <li>breach of these Terms and Conditions;</li>
    <li>any breach of your representations and warranties set forth in these Terms and Conditions; </li>
    <li>your violation of the rights of a third party, including but not limited to intellectual property rights; or
    </li>
    <li>any overt harmful act toward any other user of the Website/Mobile Application with whom you connected via the
      Website/Mobile Application. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the
      exclusive defense and control of any matter for which you are required to indemnify PAYBYCAL EXPERTS, and you
      agree to cooperate, at your expense, with PAYBYCAL EXPERTS defense of such claims. PAYBYCAL EXPERTS will use
      reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification
      upon becoming aware of it.</li>
  </ol>
  </p>

  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Electronic Communications, Transactions, And Signatures
  </p>

  <p>
    Visiting the Website/Mobile Application, sending us emails, and completing online forms constitute electronic
    communications. You consent to receive electronic communications, and you agree that all agreements, notices,
    disclosures, and other communications we provide to you electronically, via email and on the Website/Mobile
    Application, satisfy any legal requirement that such communication be in writing.
    <br />
    YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
    DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY PAYBYCAL EXPERTS OR VIA THE
    WEBSITE/MOBILE APPLICATION.
    <br />
    You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any
    jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments
    or the granting of credits by any means other than electronic means.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    User Data
  </p>
  <p>PAYBYCAL EXPERTS will maintain certain data that you transmit to the Website/Mobile Application for the purpose of
    managing the performance of the Website/Mobile Application, as well as data relating to your use of the
    Website/Mobile Application. Although we encrypt and perform regular routine backups of data, you are solely
    responsible for all data that you transmit or that relates to any activity you have undertaken using the
    Website/Mobile Application. You agree that we shall have no liability to you for any loss or corruption of any such
    data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    General provisions
  </p>

  <p>
    If a provision of these Terms of Use is or becomes illegal, invalid or unenforceable in any country, that will not
    affect the legality, validity or enforceability in that country of any other provision or in other jurisdictions of
    that or any other provisions of these Terms of Use.
    <br />
    Certain pages or areas on the Website/Mobile Application may contain additional terms, conditions, disclosures or
    disclaimers (“Additional Terms”). In the event of a conflict between these Terms of Use and the Additional Terms,
    the Additional Terms will govern for those pages or areas.
    <br />
    Rights of PAYBYCAL EXPERTS under these Terms of Use may be exercised as often as necessary. They are cumulative and
    not exclusive of rights or remedies provided by law, rules and regulations. They may be waived only in writing and
    specifically. Delay in the exercise or non-exercise of any such right is not a waiver of that right. An express or
    implied waiver of any of the provisions or of any breach of or default in performing any of the provisions of these
    Terms of Use will not constitute a continuing waiver and that waiver will not prevent the waiving party from
    subsequently enforcing any of the provisions of these Terms of Use not waived or from acting on any subsequent
    breach of or default by the other party under any of the provisions of these Terms of Use.<br />
    Any notice or other communication given under or in connection with these Terms of Use will be in English. All other
    documents provided under or in connection with these Terms of Use will be in English. A document in another language
    must be accompanied by a certified English translation. In such a situation the English translation will prevail,
    unless the document is a statutory or other official document.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Compliance with Laws
  </p>

  <p>
    You are responsible for complying with all applicable laws, rules and regulations, all third-party rights and all
    PAYBYCAL EXPERTS policies. You shall not use the website in a manner that violates such laws, rules and regulations,
    third parties' rights or any PAYBYCAL EXPERTS policies or in a manner that is deceptive, unethical, false or
    misleading. For avoidance of doubt, you shall not distribute or provide access to applications you develop in
    contravention of Indian laws.
    <br />
    This Agreement shall be governed by and construed in accordance with Indian Law and you hereby submit to the
    exclusive jurisdiction of the Mumbai, Maharashtra, India Courts.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Non-Waiver
  </p>

  <p>
    Any forbearance or failure by PAYBYCAL EXPERTS to enforce a provision to which you are subject shall not affect our
    right to require such performance at any subsequent time, nor shall the waiver or forbearance by PAYBYCAL EXPERTS of
    any breach of any provisions of the agreement herein be taken to be or held to be a waiver of the provision or
    provisions itself or themselves.
  </p>
<br />
  <p style={{fontSize:'20px', fontWeight:'600'}}>
    Statute of Limitations
  </p>

  <p>
    User and PAYBYCAL EXPERTS agree that any cause of action arising out of or related to these Services must commence
    within one (1) month after the cause of action arose; otherwise, such cause of action is permanently barred. If you
    do not agree with any of our Terms of Uses mentioned above, please do not read the material on any of our pages or
    do not accept PAYBYCAL EXPERTS services.
    <br />Date Last Modified <br />These Terms of Use were last modified February 24, 2023.
  </p>
  </div>
    <FooterTwo />
    </>
  )
}
