import React,{ useState } from 'react'
import Navbar from '../Navbar/Navbar'
import axios from "axios";
import { useEffect } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import "./faq.css";
import FooterTwo from '../FooterTwo/FooterTwo';

export default function Faq() {

    const [data, setData] = useState([])

    useEffect(() => {
      getData()
  }, [])
  
    const getData = () => {
      let url = "https://paybycal.com/api/coach_faq.php"
  
      axios.post(url).then((resp) => {
          console.log('new faq data', resp.data.data)
          if(resp.data.status == 'true'){
  
          setData(resp.data.data)
          }else{}
      }).catch((err) => {
          console.log('cat errrorrr', err)
      })
  }


  return (
    <>
    <Navbar />
    <div  className="faq">
    <h1 style={{textAlign:'center', padding:'3px 0px',margin:'20px'}}>FAQ</h1>
   {data.map((element, index)=>{
      return(
        <Accordion allowMultipleExpanded={false} allowZeroExpanded>
            <AccordionItem key={element.id}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    {element.faq_title}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    
                    {element.faq_conent}
                    
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
      )
    })}
    <Accordion allowMultipleExpanded={false} allowZeroExpanded>
            <AccordionItem >
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is Online Plan?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    
                • Create your client's daily activity plan on Paybycal app for 30 days. <br />
                • Create your client's plan within 48 hours of enrolment to avoid plan cancellation. <br />
                • Gold calcoins will be the actual amount you want to charge.<br />
                • Gold calcoins can be transferred to your bank account, also they can be used for purchasing products from stores.<br />
                • Silver calcoins will be the discount amount that you want to give to your clients.<br />
                • Silver calcoins can only be used for purchasing products from the store.
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        <Accordion allowMultipleExpanded={false} allowZeroExpanded>
            <AccordionItem >
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is Offline/Trial Plan?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    
                • Mention where you’re comfortable to train your client. <br />
                • Coordinate with your client for personal training session days. <br />
                • Gold calcoins will be the actual amount you want to charge.<br />
                • Gold calcoins can be transferred to your bank account, also they can be used for purchasing products from stores.<br />
                • Silver calcoins will be the discount amount that you want to give to your clients.<br />
                • Silver calcoins can only be used for purchasing products from the store.
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
</div>
<FooterTwo />
</>
  )
}
