import React from 'react'
import "./findout.css";

export default function FindOut() {
    return (
        <div className='findMainDiv'>
            <div className="findmain">
                <div className="FindFirst">
                    <h2>Independent Experts</h2>
                </div>
                <div className="FindSecond">
                    <p>Over 1000 HD video exercises for you to make custom workouts for your clients, home workouts, strength training, HIIT training, Yoga, rehab exercises etc</p>
                </div>
                {/* <div className="FindThree">
                    <a target='_blank' href="https://play.google.com/store/apps/details?id=com.paybycalexperts "><h2>FIND OUT MORE</h2></a>
                    
                </div> */}
            </div>

            <div className="findmain">
                <div className="FindFirst">
                    <h2>Independent Nutritionists</h2>
                </div>
                <div className="FindSecond">
                    <p>Target calorie counts, macro targets, or fully detailed meal plans with verified nutrition database of over thousands of food items</p>
                </div>
                {/* <div className="FindThree">
                <a target='_blank' href="https://play.google.com/store/apps/details?id=com.paybycalexperts "><h2>FIND OUT MORE</h2></a>
                </div> */}
            </div>

            <div className="findmain">
                <div className="FindFirst">
                    <h2>Benefits of our app</h2>
                </div>
                <div className="FindSecond">
                    <p>Make your clients plan on your paybycal expert app and it gets updated on your clients paybycal app with just a snap. Modify plans on the go, your clients can log their calorie intake & calorie expenditure giving you real-time feedback as the day unfolds</p>
                </div>
                {/* <div className="FindThree">
                <a target='_blank' href="https://play.google.com/store/apps/details?id=com.paybycalexperts "><h2>FIND OUT MORE</h2></a>
                </div> */}
            </div>
        </div>
    )
}
