import React from 'react'
import "./client.css";
import img1 from '../../asset/img/whatsay/PBC.png'

export default function Client() {
  return (
    <div className='clientMainDiv'>
        <div className="clientBox">
            <div className="cBox">
                <img src={img1} alt="" />
            </div>
            <div className="clientText">
                <p>This is the platform to make profits and get engaged with your clients on a personalized level</p>
            </div>
            <div className="clientText2">
                <p>Take your consultation on a next level and deliver an experience to your clients which they have never seen before. With nutrition, fitness and habit coaching features, plus progress tracking, customized messaging and more - it's everything that you ever wanted to have!</p>
            </div>
        </div>

    </div>
  )
}
